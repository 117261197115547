import React from 'react';
import { Stack, Typography, Box, Divider } from '@mui/material';
import { heather, midnight } from 'src/constants/colors';
import browser from '../assets/icn-browser-light.svg';
import { Icons } from '../components/alertManager/CustomIconSelect';

export const replaceLinks = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const links = doc.getElementsByTagName('a');

  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    link.setAttribute('target', '_blank');
  }

  return doc.documentElement.innerHTML;
};

export const handleCommonPreviewUi = (element: any) => {
  return (
    <Stack
      gap={1}
      alignItems={'start'}
      sx={{
        width: '460px',
        minHeight: '200px',
        height: '100%',
        maxHeight: '400px',
        overflow: 'scroll',
        padding: '10px 18px',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Typography
        sx={{
          color: '#191b36',
          lineHeight: '29px',
          fontSize: '24px',
          fontFamily: 'Rota !important',
          fontWeight: '700 !important',
        }}
      >
        Content
      </Typography>

      <Typography
        sx={{
          fontSize: '22px',
          lineHeight: '29px',
          fontWeight: '500 !important',
          color: '#191B36',
        }}
        dangerouslySetInnerHTML={{ __html: replaceLinks(element) }}
      ></Typography>
    </Stack>
  );
};

const renderIcon = (icon: string) => {
  const IconEle = Icons[icon];
  if (IconEle) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span className="svg-color-Midnight">
          <IconEle width="30px" height="30px" />
        </span>
      </div>
    );
  } else {
    return (
      <Typography
        sx={{
          color: midnight,
          fontSize: '16px',
          fontWeight: 600,
          width: '100px',
          paddingLeft: '5px',
        }}
      >
        Custom Icon
      </Typography>
    );
  }
};

export const handleNotificationInboxPreviewUi = (
  element: any,
  selectedIcon: string,
  alertTitle: string,
  alertHistorySubject: string,
) => {
  return (
    <Stack
      gap={'30px'}
      sx={{
        width: '492px',
        height: '100%',
        minHeight: '200px',
        maxHeight: '400px',
        borderRadius: '12px',
        overflow: 'scroll',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'self-start'}
        sx={{
          backgroundColor: ' #f5f6fb',
          borderRadius: '12px',
          padding: '20px 24px',
        }}
      >
        <Stack flexDirection={'row'} alignItems={'self-start'} gap={2}>
          {renderIcon(selectedIcon)}
          <Stack gap={0.25}>
            <Typography
              sx={{
                fontSize: '20px',
                lineHeight: '29px',
                fontFamily: 'RotaBold !important',
                fontWeight: '700 !important',
              }}
            >
              {alertTitle}
            </Typography>
            <Typography
              sx={{
                color: '#80829d',
                fontSize: '18px',
                fontFamily: 'RotaSemiBold !important',
                lineHeight: '24px',
              }}
            >
              {alertHistorySubject}
            </Typography>
          </Stack>
        </Stack>
        <Typography
          sx={{
            fontSize: '16px',
            color: '#80829d',
            fontWeight: '500 !important',
            lineHeight: '24px',
          }}
        >
          Now
        </Typography>
      </Stack>

      <Stack
        sx={{
          width: '100%',
          padding: '20px 24px',
          backgroundColor: '#f5f6fb',
          borderRadius: '12px',
        }}
        gap={0.25}
      >
        <Typography
          sx={{
            color: '#191B36',
            fontSize: '23px',
            lineHeight: '30px',
            fontFamily: 'RotaBold !important',
            fontWeight: '700 !important',
          }}
        >
          {alertTitle}
        </Typography>
        <Typography
          sx={{
            color: '#80829d',
            fontSize: '17px',
            lineHeight: '24px',
            fontFamily: 'RotaSemiBold !important',
          }}
        >
          Date and time of this message
        </Typography>
        <Typography
          sx={{
            color: '#191B36',
            fontSize: '24px',
            lineHeight: '30px',
            fontFamily: 'RotaBold !important',
            fontWeight: '700 !important',
            marginTop: '8px !important',
          }}
        >
          {alertHistorySubject}
        </Typography>
        <Typography
          sx={{
            fontSize: '22px',
            lineHeight: '29px',
            fontWeight: 500,
            color: '#191B36',
          }}
          dangerouslySetInnerHTML={{ __html: replaceLinks(element) }}
        ></Typography>
      </Stack>
    </Stack>
  );
};

export const handleBrowserPreviewUi = (element: any, subject: string) => {
  return (
    <Stack
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'flex-start'}
      gap={1.2}
      sx={{
        width: '492px',
        padding: '20px 22px',
        height: '100%',
        overflow: 'scroll',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Box
        sx={{
          width: '30px',
          height: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          padding: '7px',
          backgroundColor: '#262949',
        }}
      >
        <img
          src={browser}
          alt=""
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            fill: 'red',
          }}
        />
      </Box>

      <Stack sx={{ width: '95%' }} gap={0.5} p={0.75}>
        <Stack flexDirection={'row'} justifyContent={'space-between'} gap={0.5}>
          <Typography
            sx={{
              color: '#80829D',
              fontSize: '16px',
              fontFamily: 'RotaSemiBold !important',
              lineHeight: '22px',
            }}
          >
            BROWSER
          </Typography>
          <Typography
            sx={{
              color: '#80829D',
              fontSize: '16px',
              lineHeight: '22.32px',
              fontFamily: 'RotaSemiBold !important',
              fontWeight: '600 !important',
            }}
          >
            Now
          </Typography>
        </Stack>

        <Typography
          sx={{
            fontSize: '23px',
            lineHeight: '31.73px',
            fontFamily: 'RotaBold !important',
            fontWeight: '700 !important',
          }}
        >
          {subject}
        </Typography>

        <Divider
          sx={{
            borderColor: heather,
            borderWidth: '1px',
            margin: '0 0 4px 0',
          }}
        />

        <Typography
          sx={{
            fontSize: '22px',
            lineHeight: '29px',
            fontWeight: 500,
            color: '#191B36',
          }}
          dangerouslySetInnerHTML={{ __html: replaceLinks(element) }}
        ></Typography>
      </Stack>
    </Stack>
  );
};
