import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { InputField } from '@components/InputField';
import {
  dusk,
  heather,
  midnight,
  newBlueglass,
  seaglassDarker,
  white,
} from 'src/constants/colors';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { ReactComponent as ArrowDown } from '../../assets/icn-chevron-down.svg';
import { getUserTimeZone } from 'src/util/dateUtils';

type UtcData = {
  hours: string;
  minutes: string;
};

type DateProps = {
  startingDate?: string;
  selectedDate?: string;
  onChange?: (dateTime: string) => void;
  onSubmit?: (dateTime: string) => void;
  setScheduledDate?: (scheduledDate: string) => void;
  isEventScheduled?: boolean;
  setIsEventScheduled?: (isEventScheduled: boolean) => void;
  isDisable?: boolean;
  isLoading?: boolean;
  showUTC?: boolean;
};

const CustomDatePicker: React.FC<DateProps> = ({
  startingDate = '',
  selectedDate = '',
  onChange,
  onSubmit,
  setScheduledDate,
  isEventScheduled = false,
  setIsEventScheduled,
  isDisable = false,
  isLoading = false,
  showUTC = false,
}) => {
  const isSelected = selectedDate !== '';
  const time = new Date();
  const disabledIcon = '#c3c3c3';

  const currentHours = time.getHours();
  const currentMinutes = time.getMinutes();
  const currentDate = formatToYearMonthDay(time);

  const displayTime =
    isSelected && showUTC
      ? selectedDate?.slice(
          selectedDate?.indexOf('T') + 1,
          selectedDate?.indexOf('.'),
        )
      : isSelected && !showUTC
      ? selectedDate?.slice(selectedDate?.indexOf(' ') + 1)?.trim()
      : '';

  const fetchedStartTime =
    isSelected && showUTC
      ? startingDate?.slice(
          startingDate?.indexOf('T') + 1,
          startingDate?.indexOf('.'),
        )
      : isSelected && !showUTC
      ? startingDate?.slice(selectedDate?.indexOf(' ') + 1)?.trim()
      : '';
  const dateParam =
    isSelected && showUTC
      ? new Date(selectedDate)
      : isSelected && !showUTC
      ? new Date(`${selectedDate.trim()}`)
      : time;
  const [date, setDate] = useState<string>(formatToYearMonthDay(dateParam));

  const [utcData, setUtcData] = useState<UtcData>({
    hours: isSelected ? displayTime?.slice(0, 2) : formatToString(currentHours),
    minutes: isSelected
      ? displayTime?.slice(3, 5)
      : formatToString(currentMinutes),
  });
  const [btnDisable, setBtnDisable] = useState({
    hoursIncrease: false,
    hoursDecrease: false,
    minutesIncrease: false,
    minutesDecrease: false,
  });
  const fetchedHours = parseInt(
    fetchedStartTime?.slice(0, fetchedStartTime?.indexOf(':')),
  );
  const fetchedMinutes = parseInt(
    fetchedStartTime?.slice(
      fetchedStartTime?.indexOf(':') + 1,
      fetchedStartTime?.lastIndexOf(':'),
    ),
  );
  const dates = getCurrentAndFutureDate();

  const isTodayOrCurrentDate =
    date === dates?.currentDate || date === currentDate;
  const isFutureDate = date === dates?.futureDate;

  const btnStyles = {
    p: 0,
    minWidth: '20px !important',
    backgroundColor: 'transparent',
    height: '20px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: 'red !important',
    },
  };

  function formatToYearMonthDay(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function getCurrentAndFutureDate() {
    const currentDate =
      startingDate === '' ? new Date(Date.now()) : new Date(startingDate);
    const futureDate = new Date(currentDate);
    futureDate.setMonth(futureDate.getMonth() + 3);
    return {
      currentDate: formatToYearMonthDay(currentDate),
      futureDate: formatToYearMonthDay(futureDate),
    };
  }

  const handleDateChange = (data: Dayjs | null) => {
    if (data) {
      const formattedDate = data.format('YYYY-MM-DD');
      setDate(formattedDate);
      if (onChange) {
        onChange(`${formattedDate}T${utcData.hours}:${utcData.minutes}:00`);
      }
    } else {
      setDate('');
    }
  };

  function formatToString(element: number) {
    return element?.toString()?.padStart(2, '0');
  }

  const handleUpdate = (key: keyof UtcData, method: string) => {
    setUtcData((prev) => {
      const currentValue = parseInt(prev[key]) || 0;
      let newValue = currentValue;
      const minutes = parseInt(prev?.minutes);
      const hours = parseInt(prev?.hours);

      if (method === 'increment') {
        if (key === 'hours') {
          if (currentValue === 23) {
            if (isTodayOrCurrentDate && !showUTC) {
              return {
                ...prev,
                hours: formatToString(currentHours),
                minutes: formatToString(
                  minutes > currentMinutes ? minutes : currentMinutes,
                ),
              };
            } else {
              return {
                ...prev,
                hours: '00',
                minutes: prev.minutes,
              };
            }
          } else if (isFutureDate && !showUTC) {
            newValue = currentValue + 1;
            return {
              ...prev,
              hours: formatToString(newValue),
              minutes: formatToString(
                newValue === currentHours
                  ? minutes > currentMinutes
                    ? currentMinutes
                    : minutes
                  : newValue === fetchedHours
                  ? minutes > fetchedMinutes
                    ? fetchedMinutes
                    : minutes
                  : minutes,
              ),
            };
          } else {
            newValue = currentValue + 1;
            return {
              ...prev,
              hours: formatToString(newValue),
              minutes: prev.minutes,
            };
          }
        } else if (key === 'minutes') {
          if (currentValue === 59) {
            if (isTodayOrCurrentDate && hours === 23 && !showUTC) {
              return {
                ...prev,
                hours: formatToString(currentHours),
                minutes: formatToString(currentMinutes),
              };
            } else {
              newValue = 0;
              const updatedHour = (hours + 1) % 24;
              return {
                ...prev,
                minutes: '00',
                hours: formatToString(updatedHour),
              };
            }
          } else {
            newValue = currentValue + 1;
          }
        }
      } else if (method === 'decrement') {
        if (key === 'hours') {
          if (hours === 0 && isFutureDate && !showUTC) {
            return {
              ...prev,
              hours: formatToString(fetchedHours ? fetchedHours : currentHours),
              minutes: formatToString(
                minutes > (fetchedMinutes ? fetchedMinutes : currentMinutes)
                  ? fetchedMinutes
                    ? fetchedMinutes
                    : currentMinutes
                  : minutes,
              ),
            };
          }

          newValue = currentValue === 0 ? 23 : currentValue - 1;
          return {
            ...prev,
            hours: newValue.toString().padStart(2, '0'),
            minutes:
              isTodayOrCurrentDate && newValue === currentHours
                ? formatToString(
                    minutes > currentMinutes ? minutes : currentMinutes,
                  )
                : prev?.minutes,
          };
        } else if (key === 'minutes') {
          if (currentValue === 0) {
            if (isFutureDate && hours === 0 && !showUTC) {
              return {
                ...prev,
                hours: formatToString(currentHours),
                minutes: formatToString(currentMinutes),
              };
            }

            newValue = 59;
            const updatedHour = (parseInt(prev.hours) - 1 + 24) % 24;
            return {
              ...prev,
              minutes: '59',
              hours: formatToString(updatedHour),
            };
          } else {
            newValue = currentValue - 1;
          }
        }
      }

      const updatedUtcData = {
        ...prev,
        [key]: newValue.toString().padStart(2, '0'),
      };

      if (onChange) {
        onChange(
          `${date}T${updatedUtcData.hours}:${updatedUtcData.minutes}:00`,
        );
      }

      return updatedUtcData;
    });
  };

  useEffect(() => {
    if (!showUTC) {
      const currentMinutes = time.getMinutes();
      const currentHours = time.getHours();

      setBtnDisable(() => {
        const isSameDate =
          date === dates?.currentDate || date === formatToYearMonthDay(time);
        const isFutureDate = date === dates?.futureDate;

        return {
          hoursDecrease: isSameDate
            ? parseInt(utcData.hours) <= currentHours
            : !startingDate && parseInt(utcData.hours) <= fetchedHours,

          hoursIncrease: isFutureDate
            ? startingDate
              ? parseInt(utcData.hours) >= fetchedHours
              : parseInt(utcData.hours) >= currentHours
            : false,
          minutesDecrease: isSameDate
            ? parseInt(utcData.hours) === currentHours &&
              parseInt(utcData.minutes) <= currentMinutes
            : isFutureDate
            ? parseInt(utcData.hours) === fetchedHours &&
              parseInt(utcData.minutes) > fetchedMinutes
            : false,

          minutesIncrease: isFutureDate
            ? startingDate
              ? parseInt(utcData.hours) === fetchedHours &&
                parseInt(utcData.minutes) >= fetchedMinutes
              : parseInt(utcData.hours) === currentHours &&
                parseInt(utcData.minutes) >= currentMinutes
            : false,
        };
      });
    }
  }, [utcData, date]);

  useEffect(() => {
    if (!isEventScheduled) {
      return;
    }
    const hours = utcData.hours.toString().padStart(2, '0');
    const minutes = utcData.minutes.toString().padStart(2, '0');
    const selectedUtcTime = `${date} ${hours}:${minutes}:00`;
    if (isEventScheduled && setIsEventScheduled && setScheduledDate) {
      setScheduledDate(selectedUtcTime);
      setIsEventScheduled(false);
    }
  }, [isEventScheduled]);

  useEffect(() => {
    if (!showUTC) {
      const isHourLower = parseInt(utcData?.hours) <= currentHours;
      if (date === currentDate) {
        setUtcData((prev) => {
          return {
            hours: isHourLower ? formatToString(currentHours) : prev?.hours,
            minutes:
              isHourLower && parseInt(prev?.minutes) <= currentMinutes
                ? formatToString(currentMinutes)
                : prev?.minutes,
          };
        });
      } else if (date === dates?.futureDate) {
        setUtcData((prev) => {
          const hours = parseInt(prev?.hours);
          const minutes = parseInt(prev?.minutes);
          return {
            ...prev,
            hours: formatToString(
              !startingDate
                ? hours > currentHours
                  ? currentHours
                  : hours
                : hours > fetchedHours
                ? fetchedHours
                : hours,
            ),
            minutes: formatToString(
              !startingDate
                ? minutes > currentMinutes
                  ? currentMinutes
                  : minutes
                : minutes > fetchedMinutes
                ? fetchedMinutes
                : minutes,
            ),
          };
        });
      }
    }
  }, [date]);

  return (
    <Stack
      flexDirection={'row'}
      sx={{
        width: '500px',
        height: '310px',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
        padding: '15px 0',
        backgroundColor: white,
      }}
    >
      <Box
        sx={{
          borderRight: '3px solid #E8EBF5',
          width: '340px',
          height: '100%',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            disabled={isDisable}
            value={date ? dayjs(date, 'YYYY-MM-DD') : null}
            onChange={handleDateChange}
            views={['year', 'month', 'day']}
            minDate={
              startingDate
                ? dayjs(formatToYearMonthDay(time), 'YYYY-MM-DD')
                : dayjs(dates?.currentDate, 'YYYY-MM-DD')
            }
            maxDate={
              showUTC ? undefined : dayjs(dates.futureDate, 'YYYY-MM-DD')
            }
            disableHighlightToday
            showDaysOutsideCurrentMonth
            slotProps={{
              calendarHeader: {
                sx: {
                  mt: 0,
                  button: {
                    color: dusk,
                  },
                },
              },
              day: {
                sx: {
                  fontWeight: 'bold',
                  color: midnight,
                  '&.Mui-selected': {
                    backgroundColor: `${newBlueglass} !important`,
                    color: 'white !important',
                    borderRadius: '0',
                  },
                },
              },
            }}
            sx={{
              height: '290px',
              // Target the month/year display (header section)
              '.MuiPickersCalendarHeader-root': {
                fontWeight: 'bold',
                fontSize: '16px',
              },
              // Target the day-of-week labels (S, M, T...)
              '.MuiPickersCalendarHeader-labelContainer': {
                fontWeight: 'bold',
                fontSize: '14px',
              },
              // Target the month buttons in month view
              '.MuiPickersMonth-monthButton': {
                fontWeight: 'bold',
                fontSize: '16px',
              },
              // Ensure the border-radius is 0 for selected month
              '.MuiPickersMonth-monthButton.Mui-selected': {
                backgroundColor: `${newBlueglass} !important`,
                color: 'white !important',
                borderRadius: '0',
              },
              // Target the year selection dropdown
              '.MuiPickersYear-yearButton': {
                fontWeight: 'bold',
                fontSize: '16px',
              },
              // Selected year styling
              '.MuiPickersYear-yearButton.Mui-selected': {
                backgroundColor: `${newBlueglass} !important`,
                color: 'white !important',
                borderRadius: 0,
              },
              '.Mui-selected': {
                backgroundColor: `${newBlueglass} !important`,
                color: 'white !important',
              },
            }}
          />
        </LocalizationProvider>
      </Box>
      <Stack sx={{ height: '100%', width: '165px' }} alignItems={'center'}>
        <Box pt={1.4}>
          <Typography sx={{ fontWeight: 700, fontSize: '18px' }}>
            {showUTC ? 'UTC' : getUserTimeZone()}
          </Typography>
        </Box>
        <Stack display={'flex'} sx={{ height: '100% ' }}>
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            sx={{ height: '100% ' }}
            gap={1}
          >
            <Stack sx={{ width: '45px' }} gap={1}>
              <PrimaryButton
                buttonTitle=""
                startIcon={
                  <ArrowDown
                    style={{
                      transform: 'rotate(180deg)',
                      fill: btnDisable?.hoursIncrease ? disabledIcon : dusk,
                    }}
                  />
                }
                customSx={btnStyles}
                onClick={() => {
                  handleUpdate('hours', 'increment');
                }}
                disabled={btnDisable?.hoursIncrease || isDisable}
              />
              <InputField
                label=""
                autoComplete={'off'}
                customSx={{
                  width: '45px',
                  height: '45px',
                  border: `1px solid ${heather}`,
                  borderRadius: '4px',
                  padding: '1px 1px',
                  overflowY: 'hidden',
                  my: 0,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none !important',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none !important',
                    },
                  },

                  '& .MuiOutlinedInput-input': {
                    padding: '11px 9px',
                    backgroundColor: white,
                    textAlign: 'center',
                  },
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: midnight,
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    border: 'none',
                  },
                }}
                disabled={isDisable}
                value={utcData?.hours !== null ? utcData?.hours : ''}
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement)?.value;
                  const numericValue = parseInt(value);
                  if (
                    !isNaN(numericValue) &&
                    numericValue >= 0 &&
                    numericValue <= 23
                  ) {
                    setUtcData((prev) => ({
                      ...prev,
                      hours: value,
                    }));
                  } else if (value === '') {
                    setUtcData((prev) => ({
                      ...prev,
                      hours: '',
                    }));
                  }
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  const value = e.target.value.trim();
                  const numericValue = parseInt(value);
                  if (showUTC) return;

                  if (value === '') {
                    setUtcData((prev) => ({
                      ...prev,
                      hours: formatToString(currentHours),
                    }));
                    return;
                  }

                  const isValidNumber =
                    !isNaN(numericValue) &&
                    numericValue >= 0 &&
                    numericValue <= 23;
                  if (!isValidNumber) return;

                  if (isTodayOrCurrentDate && numericValue <= currentHours) {
                    setUtcData((prev) => ({
                      ...prev,
                      hours: formatToString(currentHours),
                      minutes: formatToString(
                        parseInt(prev?.minutes) < currentMinutes
                          ? currentMinutes
                          : parseInt(prev?.minutes),
                      ),
                    }));
                    return;
                  }
                  if (isFutureDate && numericValue) {
                    const hoursToCompare = startingDate
                      ? fetchedHours
                      : currentHours;
                    const minutesToCompare = startingDate
                      ? fetchedMinutes
                      : currentMinutes;

                    if (numericValue >= hoursToCompare) {
                      setUtcData((prev) => ({
                        ...prev,
                        hours: formatToString(hoursToCompare),
                        minutes: formatToString(
                          parseInt(prev?.minutes) > minutesToCompare
                            ? minutesToCompare
                            : parseInt(prev?.minutes),
                        ),
                      }));
                    }
                  }
                }}
                inputAttrProps={{
                  maxLength: 2,
                }}
              />
              <PrimaryButton
                buttonTitle=""
                startIcon={
                  <ArrowDown
                    style={{
                      fill: btnDisable?.hoursDecrease ? disabledIcon : dusk,
                    }}
                  />
                }
                customSx={btnStyles}
                onClick={() => {
                  handleUpdate('hours', 'decrement');
                }}
                disabled={btnDisable?.hoursDecrease || isDisable}
              />
            </Stack>

            <Stack>
              <Typography sx={{ color: midnight, fontSize: '22px' }}>
                :
              </Typography>
            </Stack>

            <Stack sx={{ width: '45px' }} gap={1}>
              <PrimaryButton
                buttonTitle=""
                startIcon={
                  <ArrowDown
                    style={{
                      transform: 'rotate(180deg)',
                      fill: btnDisable?.minutesIncrease ? disabledIcon : dusk,
                    }}
                  />
                }
                customSx={btnStyles}
                onClick={() => {
                  handleUpdate('minutes', 'increment');
                }}
                disabled={btnDisable?.minutesIncrease || isDisable}
              />

              <InputField
                label=""
                autoComplete={'off'}
                customSx={{
                  width: '45px',
                  height: '45px',
                  overflowY: 'hidden',
                  border: `1px solid ${heather}`,
                  borderRadius: '4px',
                  padding: '1px 1px',
                  my: 0,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none !important',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none !important',
                    },
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: '11px 9px',
                    background: 'white',
                    textAlign: 'center',
                  },
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: dusk,
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    border: 'none',
                  },
                }}
                disabled={isDisable}
                value={utcData?.minutes !== null ? utcData?.minutes : ''}
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement)?.value;
                  const numericValue = parseInt(value);
                  if (
                    !isNaN(numericValue) &&
                    numericValue >= 0 &&
                    numericValue <= 59
                  ) {
                    setUtcData((prev) => ({
                      ...prev,
                      minutes: value,
                    }));
                  } else if (value === '') {
                    setUtcData((prev) => ({
                      ...prev,
                      minutes: '',
                    }));
                  }
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  const value = e.target.value.trim();
                  const numericValue = parseInt(value);
                  const shouldCheck =
                    parseInt(utcData.hours) ===
                    (startingDate ? fetchedHours : currentHours);

                  if (showUTC) return;

                  if (value === '') {
                    setUtcData((prev) => ({
                      ...prev,
                      minutes: formatToString(currentMinutes),
                    }));
                    return;
                  }

                  const isValidNumber =
                    !isNaN(numericValue) &&
                    numericValue >= 0 &&
                    numericValue <= 59;
                  if (!isValidNumber) return;

                  if (
                    isTodayOrCurrentDate &&
                    parseInt(utcData?.hours) === currentHours &&
                    numericValue < currentMinutes
                  ) {
                    setUtcData((prev) => ({
                      ...prev,
                      minutes: formatToString(currentMinutes),
                    }));
                    return;
                  }

                  if (isFutureDate) {
                    const minutesToCompare = startingDate
                      ? fetchedMinutes
                      : currentMinutes;

                    if (numericValue > minutesToCompare && shouldCheck) {
                      setUtcData((prev) => ({
                        ...prev,
                        minutes: formatToString(minutesToCompare),
                      }));
                    }
                  }
                }}
                inputAttrProps={{ maxLength: 2 }}
              />
              <PrimaryButton
                buttonTitle=""
                startIcon={
                  <ArrowDown
                    style={{
                      fill: btnDisable?.minutesDecrease ? disabledIcon : dusk,
                    }}
                  />
                }
                customSx={btnStyles}
                onClick={() => {
                  handleUpdate('minutes', 'decrement');
                }}
                disabled={btnDisable?.minutesDecrease || isDisable}
              />
            </Stack>
          </Stack>
        </Stack>
        {onSubmit && (
          <PrimaryButton
            buttonTitle="Save"
            isLoading={isLoading}
            disabled={isLoading}
            customSx={{
              mt: 2,
              width: '70%',
              backgroundColor: newBlueglass,
              color: white,
              '&:hover': {
                background: seaglassDarker,
              },
              '&:disabled': {
                background: seaglassDarker,
              },
            }}
            onClick={() => {
              const hours = utcData.hours.toString().padStart(2, '0');
              const minutes = utcData.minutes.toString().padStart(2, '0');
              const selectedUtcTime = `${date} ${hours}:${minutes}:00`;
              onSubmit(selectedUtcTime);
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default CustomDatePicker;
